<template>
  <el-tooltip effect="dark" :content="content" :placement="placement">
    <i class="el-icon-question" :size="14"></i>
  </el-tooltip>
</template>
<script>
export default {
  name: "ExplainTip",
  props: {
    placement: {
      type: String,
      default: "top-start",
    },
    content: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>
